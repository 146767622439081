export const accommodationsData = [
  {
    city: 'Hunter',
    name: 'The Kaatskill Mountain Club',
    state: 'NY',
    street: '62 Liftside Drive',
    phoneNumber: '5182635580',
    websiteAddress:
      'https://www.huntermtn.com/on-site-lodging/the-kaatskill-mountain-club/',
    zip: '12442',
  },
  {
    city: 'Hunter',
    name: 'The Hunter Inn',
    state: 'NY',
    street: '7433 Main Street',
    phoneNumber: '5182633777',
    websiteAddress: 'http://www.hunterinn.com/',
    zip: '12442',
  },
  {
    city: 'Roxbury',
    name: 'Roxbury Manor',
    state: 'NY',
    street: ' 53642 State Highway 30',
    phoneNumber: '5163174573',
    websiteAddress: 'https://www.roxburyrocks.com/',
    zip: '12474',
  },
  {
    city: 'Roxbury',
    name: 'The Roxbury Motel',
    state: 'NY',
    street: '2258 County Highway 41',
    phoneNumber: '6073267200',
    websiteAddress: 'https://theroxburyexperience.com/',
    zip: '12474',
  },
  {
    city: 'Roxbury',
    name: 'The Roxbury at Stratton Falls',
    state: 'NY',
    street: '48 County Road 41',
    phoneNumber: '6073267200',
    websiteAddress: 'https://theroxburyexperience.com/',
    zip: '12474',
  },
  {
    city: 'Roxbury',
    name: 'Green Label Home Rentals',
    state: 'NY',
    street: '50 Maple Lane',
    phoneNumber: '8009139813',
    websiteAddress: 'https://www.greenlabelhome.com/',
    zip: '12474',
  },
];

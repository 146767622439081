import React from 'react';

export default function ZolaLogo({ ...restProps }) {
  return (
    <svg
      fill="currentColor"
      fillRule="evenodd"
      style={{ height: '1em' }}
      viewBox="0 0 755.7 100.5"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>Zola logo</title>
      <g>
        <path
          className="st0"
          d="M366.7,84.3v9.9c0,2.1-1.8,3.8-3.9,3.7l-75.3-0.1c-2.1,0-3.8-1.7-3.7-3.8c0,0,0-10.8,0-11.3
    c0-0.9,0.4-1.8,1.1-2.5l53.8-60h-54.2c-2.1,0-3.8-1.7-3.7-3.7l0.1-10.2c0-2.1,1.7-3.8,3.8-3.7l75.2,0.1c2.1,0,3.8,1.7,3.7,3.8
    c0,0,0,9.2,0,10.7c0,1.1-0.3,2-1,2.9l-53.7,60.4h54C365,80.5,366.7,82.2,366.7,84.3"
        />
        <path
          className="st0"
          d="M456.8,99.1c-27-0.2-48.8-22.3-48.6-49.4c0.2-27,22.3-48.8,49.4-48.6c27,0.2,48.8,22.3,48.6,49.4
    C506,77.5,483.9,99.3,456.8,99.1 M457.4,19.5c-16.9-0.1-30.7,13.5-30.8,30.3c-0.1,16.9,13.5,30.7,30.3,30.8
    c16.9,0.1,30.7-13.5,30.8-30.3C487.9,33.5,474.3,19.7,457.4,19.5"
        />
        <path
          className="st0"
          d="M617.2,82.7v12.7c0,1.4-1.5,2.6-3.2,2.5h-52.6c-1.4,0-2.5-1.1-2.5-2.5V5.2c0-1.4,1.8-2.5,3.2-2.5h12.8
    c1.4,0,2.5,1.1,2.5,2.5v75h36.7C615.8,80.2,617.2,81.3,617.2,82.7"
        />
        <path
          className="st0"
          d="M753,97.9h-13.7c-1.1,0-2.1-0.7-2.5-1.6l-8.2-19.2H684l-8.6,19.3c-0.4,1-1.4,1.6-2.5,1.6H659
    c-2,0-3.3-2.1-2.5-3.9l40.9-89.6c0.5-1,1.3-1.7,2.3-1.7c2.7,0,12.8,0,14.3,0c1.1,0,1.9,0.8,2.3,1.8l39.2,89.5
    C756.3,95.9,754.9,97.9,753,97.9 M720.8,59.4L706.6,27l-14.7,32.4H720.8z"
        />
      </g>
      <path
        className="st1"
        d="M118.7,11.7c0.6-0.6,1.2-1.2,1.8-1.7l0,0C119.9,10.5,119.3,11.1,118.7,11.7z"
      />
      <path
        className="st1"
        d="M122.2,11.6c-0.5-0.5-1-1-1.5-1.4C121.2,10.6,121.7,11.1,122.2,11.6z"
      />
      <path
        className="st2"
        d="M120.7,10.1c-0.1-0.1-0.2-0.2-0.2-0.2l0,0C120.6,10,120.7,10.1,120.7,10.1z"
      />
      <path
        className="st3"
        d="M120.5,63.9c-0.4-0.5-0.8-1-1.2-1.4c-0.1-0.1-0.2-0.2-0.2-0.3c-0.3-0.4-0.6-0.8-0.9-1.1
  c-0.1-0.1-0.2-0.3-0.3-0.4c-0.3-0.4-0.5-0.7-0.8-1.1c-0.1-0.1-0.2-0.3-0.3-0.4c-0.3-0.5-0.7-0.9-1-1.4c0,0,0,0,0-0.1
  c-0.3-0.5-0.7-1-1-1.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.2-0.4-0.4-0.7-0.6-1.1c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.3-0.4-0.7-0.6-1
  c-0.1-0.2-0.2-0.3-0.2-0.5c-0.3-0.5-0.5-1-0.7-1.5s-0.5-1-0.7-1.5c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.4-0.3-0.7-0.4-1.1
  c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-0.4-0.2-0.7-0.3-1.1c-0.1-0.2-0.1-0.4-0.2-0.5c-0.1-0.4-0.2-0.9-0.3-1.3c0-0.1,0-0.2-0.1-0.3
  c-0.1-0.5-0.2-1-0.3-1.6c0-0.2-0.1-0.3-0.1-0.5c-0.1-0.4-0.1-0.7-0.2-1.1c0-0.2-0.1-0.4-0.1-0.6c0-0.3-0.1-0.7-0.1-1
  c0-0.2,0-0.4-0.1-0.6c0-0.4,0-0.7-0.1-1.1c0-0.2,0-0.3,0-0.5c0-0.5,0-1,0-1.5c0-0.2,0-0.4,0-0.6c0-0.3,0-0.7,0-1
  c0-0.2,0-0.5,0.1-0.7c0-0.3,0.1-0.6,0.1-0.9c0-0.2,0.1-0.5,0.1-0.7c0-0.3,0.1-0.6,0.1-0.9c0-0.2,0.1-0.4,0.1-0.7
  c0.1-0.5,0.2-1,0.3-1.4c0-0.2,0.1-0.5,0.2-0.7c0.1-0.3,0.1-0.6,0.2-0.8c0.1-0.3,0.1-0.5,0.2-0.8c0.1-0.2,0.1-0.5,0.2-0.7
  c0.1-0.3,0.2-0.5,0.2-0.8c0.1-0.2,0.2-0.5,0.2-0.7c0.1-0.3,0.2-0.5,0.3-0.8c0.1-0.2,0.2-0.4,0.3-0.6c0.2-0.5,0.4-1,0.6-1.5
  c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.3,0.3-0.5,0.4-0.8c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.3,0.3-0.5,0.4-0.8c0.1-0.2,0.2-0.3,0.3-0.5
  c0.2-0.3,0.3-0.6,0.5-0.8c0.1-0.1,0.2-0.3,0.2-0.4c0.4-0.6,0.8-1.2,1.2-1.8c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.3,0.4-0.5,0.6-0.8
  c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.3,0.4-0.5,0.6-0.8c0.1-0.1,0.2-0.3,0.4-0.4c0.2-0.3,0.5-0.5,0.7-0.8c0.1-0.1,0.2-0.2,0.3-0.3
  c0.6-0.6,1.2-1.2,1.8-1.7c-2.7-2.4-5.8-4.5-9.3-6.2c-0.9-0.4-1.7-0.7-2.6-1.1C104.2,0.8,99.8,0,95.4,0h-0.1C87.5,0,80,2.6,73.9,7.2
  c-3,2.3-5.7,5.1-7.9,8.3c1,1.5,1.9,3.1,2.7,4.8c3.9,8.3,5.2,18.6,5.2,29.1c0,0,0,0,0,0.1c-0.1,24.8-7.8,50.3-7.9,50.7
  c0.3-0.1,3.3-1.4,7.9-3.7c8.5-4.2,22.5-11.8,34.6-21.6C112.9,71.6,117,67.9,120.5,63.9L120.5,63.9L120.5,63.9L120.5,63.9L120.5,63.9
  z"
      />
      <path
        className="st4"
        d="M172.1,20.6c0.8-1.7,1.7-3.3,2.7-4.8c-2.2-3.2-4.9-6-7.9-8.3c-9.6-7.2-22.6-9.3-34.6-4.8
  c-0.9,0.3-1.7,0.7-2.6,1.1c-3.5,1.6-6.6,3.7-9.3,6.2l0,0l0,0c0.1,0.1,0.2,0.2,0.2,0.2c0.5,0.5,1,0.9,1.5,1.4
  c0.1,0.1,0.3,0.3,0.4,0.4c0.2,0.2,0.4,0.5,0.6,0.7c0.1,0.2,0.3,0.3,0.4,0.5c0.2,0.2,0.4,0.5,0.6,0.7c0.1,0.2,0.3,0.3,0.4,0.5
  c0.2,0.2,0.4,0.5,0.6,0.8c0.1,0.1,0.2,0.3,0.3,0.4c0.4,0.5,0.7,1,1,1.5c0.1,0.2,0.2,0.3,0.3,0.5c0.2,0.3,0.3,0.5,0.5,0.8
  c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.3,0.3,0.5,0.4,0.8c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.3,0.3,0.5,0.4,0.8c0.1,0.2,0.2,0.4,0.3,0.6
  c0.2,0.4,0.4,0.9,0.6,1.3c0.1,0.2,0.2,0.5,0.3,0.7c0.1,0.2,0.2,0.5,0.3,0.8c0.1,0.2,0.2,0.5,0.3,0.7c0.1,0.3,0.2,0.5,0.2,0.8
  c0.1,0.3,0.2,0.5,0.2,0.8c0.1,0.2,0.1,0.5,0.2,0.7c0.1,0.3,0.1,0.6,0.2,0.9c0,0.2,0.1,0.4,0.2,0.6c0.1,0.5,0.2,1,0.3,1.4
  c0,0.2,0.1,0.4,0.1,0.6c0,0.3,0.1,0.6,0.1,0.9c0,0.2,0.1,0.5,0.1,0.7c0,0.3,0.1,0.6,0.1,0.9c0,0.2,0,0.4,0,0.7c0,0.3,0,0.7,0,1
  c0,0.2,0,0.4,0,0.5c0,0.5,0,1,0,1.5c0,0.2,0,0.3,0,0.5c0,0.4,0,0.8-0.1,1.1c0,0.2,0,0.4-0.1,0.6c0,0.3-0.1,0.7-0.1,1
  c0,0.2,0,0.4-0.1,0.6c-0.1,0.4-0.1,0.7-0.2,1.1c0,0.2-0.1,0.3-0.1,0.5c-0.1,0.5-0.2,1-0.3,1.6c0,0.1,0,0.2-0.1,0.2
  c-0.1,0.4-0.2,0.9-0.3,1.3c-0.1,0.2-0.1,0.3-0.2,0.5c-0.1,0.4-0.2,0.7-0.3,1.1c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.4-0.3,0.7-0.4,1.1
  c-0.1,0.2-0.1,0.3-0.2,0.5c-0.2,0.5-0.4,1-0.7,1.5c-0.2,0.5-0.5,1-0.7,1.5c-0.1,0.2-0.2,0.3-0.3,0.5c-0.2,0.3-0.4,0.7-0.6,1
  c-0.1,0.2-0.2,0.3-0.3,0.5c-0.2,0.4-0.4,0.7-0.6,1.1c-0.1,0.1-0.2,0.3-0.3,0.4c-0.3,0.5-0.6,1-1,1.5c0,0,0,0-0.1,0.1
  c-0.3,0.5-0.6,0.9-1,1.4c-0.1,0.1-0.2,0.3-0.3,0.4c-0.3,0.4-0.6,0.7-0.8,1.1c-0.1,0.1-0.2,0.3-0.3,0.4c-0.3,0.4-0.6,0.8-1,1.2
  c-0.1,0.1-0.1,0.1-0.2,0.2c-0.4,0.5-0.8,1-1.3,1.5l0,0c3.5,4,7.6,7.7,11.8,11.2c12.1,9.8,26,17.4,34.6,21.6c4.6,2.3,7.7,3.6,7.9,3.7
  C174.7,100,159,48.6,172.1,20.6z"
      />
      <path
        className="st5"
        d="M58.2,49.8c0-10.5,1.3-20.9,5.2-29.2c0.8-1.7,1.7-3.3,2.7-4.8c-2.2-3.2-4.9-6-7.9-8.3
  C47.9-0.2,33.7-2.1,21.1,3.8C3,12.3-5,33.5,3.3,51.3c9.8,21.1,40.4,38.3,55,45.5c4.6,2.3,7.6,3.6,7.9,3.7
  c-0.1-0.3-7.8-25.7-7.9-50.5v-0.2H58.2z"
      />
      <path
        className="st6"
        d="M74.1,49.7c0-10.5-1.3-20.8-5.2-29.1c-0.8-1.7-1.7-3.3-2.7-4.8c-1,1.5-1.9,3.1-2.7,4.8
  c-3.9,8.3-5.2,18.7-5.2,29.2v0.1c0.1,24.8,7.8,50.2,7.9,50.5l0,0l0,0C66.3,100.1,74,74.6,74.1,49.7L74.1,49.7z"
      />
      <path
        className="st7"
        d="M219.9,3.7c-5.1-2.4-10.4-3.5-15.7-3.5h-0.1c-7.8,0-15.3,2.6-21.4,7.2c-3,2.3-5.7,5.1-7.9,8.3
  c1,1.5,1.9,3.1,2.7,4.8c3.8,8.3,5.2,18.6,5.2,29v0.4c-0.1,24.8-7.8,50.1-7.9,50.4c0.3-0.1,3.3-1.4,7.9-3.7
  c14.6-7.2,45.1-24.4,55-45.5C246,33.5,238,12.2,219.9,3.7z"
      />
      <path
        className="st8"
        d="M182.8,49.6c0-10.4-1.3-20.7-5.2-29c-0.8-1.7-1.7-3.3-2.7-4.8c-1,1.5-1.9,3.1-2.7,4.8c-13,28,2.6,79.4,2.7,79.8
  l0,0l0,0c0.1-0.3,7.8-25.7,7.9-50.4L182.8,49.6L182.8,49.6z"
      />
      <path
        className="st9"
        d="M118.7,11.7c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.2-0.5,0.5-0.7,0.8c-0.1,0.1-0.2,0.3-0.4,0.4
  c-0.2,0.3-0.4,0.5-0.6,0.8c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2,0.3-0.4,0.5-0.6,0.8c-0.1,0.1-0.1,0.2-0.2,0.3c-0.4,0.6-0.8,1.2-1.2,1.8
  c-0.1,0.1-0.2,0.3-0.2,0.4c-0.2,0.3-0.3,0.5-0.5,0.8c-0.1,0.2-0.2,0.3-0.3,0.5c-0.2,0.3-0.3,0.5-0.4,0.8c-0.1,0.2-0.2,0.4-0.3,0.6
  c-0.1,0.3-0.3,0.5-0.4,0.8c-0.1,0.2-0.2,0.4-0.3,0.6c-0.2,0.5-0.4,1-0.6,1.5c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.3-0.2,0.5-0.3,0.8
  c-0.1,0.2-0.2,0.5-0.2,0.7c-0.1,0.3-0.2,0.5-0.2,0.8c-0.1,0.2-0.1,0.5-0.2,0.7c-0.1,0.3-0.1,0.5-0.2,0.8s-0.1,0.5-0.2,0.8
  c-0.1,0.2-0.1,0.5-0.2,0.7c-0.1,0.5-0.2,1-0.3,1.4c0,0.2-0.1,0.4-0.1,0.7c0,0.3-0.1,0.6-0.1,0.9c0,0.2-0.1,0.5-0.1,0.7
  c0,0.3-0.1,0.6-0.1,0.9c0,0.2,0,0.5-0.1,0.7c0,0.3,0,0.7,0,1c0,0.2,0,0.4,0,0.6c0,0.5,0,1,0,1.5c0,0.2,0,0.3,0,0.5
  c0,0.4,0,0.7,0.1,1.1c0,0.2,0,0.4,0.1,0.6c0,0.3,0.1,0.7,0.1,1c0,0.2,0,0.4,0.1,0.6c0,0.4,0.1,0.7,0.2,1.1c0,0.2,0.1,0.3,0.1,0.5
  c0.1,0.5,0.2,1,0.3,1.6c0,0.1,0,0.2,0.1,0.3c0.1,0.4,0.2,0.9,0.3,1.3c0,0.2,0.1,0.4,0.2,0.5c0.1,0.4,0.2,0.7,0.3,1.1
  c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.4,0.3,0.7,0.4,1.1c0.1,0.2,0.1,0.3,0.2,0.5c0.2,0.5,0.4,1,0.7,1.5c0.2,0.5,0.5,1,0.7,1.5
  c0.1,0.2,0.2,0.3,0.2,0.5c0.2,0.4,0.4,0.7,0.6,1c0.1,0.2,0.2,0.3,0.3,0.5c0.2,0.4,0.4,0.7,0.6,1.1c0.1,0.1,0.2,0.3,0.3,0.4
  c0.3,0.5,0.6,1,1,1.5c0,0,0,0,0,0.1c0.3,0.5,0.6,0.9,1,1.4c0.1,0.1,0.2,0.3,0.3,0.4c0.3,0.4,0.5,0.7,0.8,1.1
  c0.1,0.1,0.2,0.3,0.3,0.4c0.3,0.4,0.6,0.8,0.9,1.1c0.1,0.1,0.2,0.2,0.2,0.3c0.4,0.5,0.8,1,1.2,1.4l0,0c0.4-0.5,0.8-1,1.3-1.5
  c0.1-0.1,0.1-0.1,0.2-0.2c0.3-0.4,0.7-0.8,1-1.2c0.1-0.1,0.2-0.3,0.3-0.4c0.3-0.4,0.6-0.7,0.8-1.1c0.1-0.1,0.2-0.3,0.3-0.4
  c0.3-0.5,0.7-0.9,1-1.4c0,0,0-0.1,0.1-0.1c0.3-0.5,0.7-1,1-1.5c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.3,0.4-0.7,0.6-1.1
  c0.1-0.2,0.2-0.3,0.3-0.5c0.2-0.3,0.4-0.7,0.6-1c0.1-0.2,0.2-0.3,0.3-0.5c0.3-0.5,0.5-1,0.7-1.5s0.5-1,0.7-1.5
  c0.1-0.2,0.1-0.3,0.2-0.5c0.1-0.4,0.3-0.7,0.4-1.1c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.4,0.2-0.7,0.3-1.1c0.1-0.2,0.1-0.3,0.2-0.5
  c0.1-0.4,0.2-0.9,0.3-1.3c0-0.1,0-0.2,0.1-0.2c0.1-0.5,0.2-1,0.3-1.6c0-0.2,0.1-0.3,0.1-0.5c0.1-0.4,0.1-0.7,0.2-1.1
  c0-0.2,0.1-0.4,0.1-0.6c0-0.3,0.1-0.7,0.1-1c0-0.2,0-0.4,0.1-0.6c0-0.4,0-0.8,0.1-1.1c0-0.2,0-0.3,0-0.5c0-0.5,0-1,0-1.5
  c0-0.2,0-0.4,0-0.5c0-0.3,0-0.7,0-1c0-0.2,0-0.4,0-0.7c0-0.3-0.1-0.6-0.1-0.9c0-0.2-0.1-0.5-0.1-0.7c0-0.3-0.1-0.6-0.1-0.9
  c0-0.2-0.1-0.4-0.1-0.6c-0.1-0.5-0.2-1-0.3-1.4c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.3-0.1-0.6-0.2-0.9c-0.1-0.2-0.1-0.5-0.2-0.7
  c-0.1-0.3-0.1-0.5-0.2-0.8s-0.2-0.5-0.2-0.8c-0.1-0.2-0.2-0.5-0.3-0.7c-0.1-0.3-0.2-0.5-0.3-0.8c-0.1-0.2-0.2-0.5-0.3-0.7
  c-0.2-0.4-0.4-0.9-0.6-1.3c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.3-0.3-0.5-0.4-0.8c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.3-0.3-0.5-0.4-0.8
  c-0.1-0.2-0.2-0.4-0.3-0.6c-0.2-0.3-0.3-0.5-0.5-0.8c-0.1-0.2-0.2-0.3-0.3-0.5c-0.3-0.5-0.7-1-1-1.5c-0.1-0.1-0.2-0.3-0.3-0.4
  c-0.2-0.3-0.4-0.5-0.6-0.8c-0.1-0.2-0.3-0.3-0.4-0.5c-0.2-0.2-0.4-0.5-0.6-0.7c-0.1-0.2-0.3-0.3-0.4-0.5c-0.2-0.2-0.4-0.5-0.6-0.7
  c-0.1-0.1-0.3-0.3-0.4-0.4c-0.5-0.5-1-1-1.5-1.4c-0.1-0.1-0.2-0.2-0.2-0.2C119.9,10.5,119.3,11.1,118.7,11.7z"
      />
    </svg>
  );
}

import React from 'react';

export default function Ampersand({ ...restProps }) {
  return (
    <svg
      viewBox="310 140 1050 990"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>and</title>
      <path
        d="M991.5,548.7c7.7,48.4,11.5,90.1,11.5,124.9c0,131.1-51.1,237.1-153.2,318C747.6,1072.5,633,1113,506.1,1113 c-56.1,0-102.9-10.9-140.4-32.8c-37.5-21.9-56.2-49-56.2-81.5c0-40.7,19.6-75.6,58.9-104.5c39.3-28.9,85.8-43.4,139.5-43.4 c31.3,0,63.8,6.2,97.4,18.6l-2.7,6.2c-35.4-10.6-67.7-15.9-96.7-15.9c-50.4,0-94.3,14.3-131.6,42.8c-37.3,28.5-56,59.8-56,94 c0,27.6,16.5,51.5,49.6,71.5c33.1,20,75.3,30,126.7,30c111.6,0,221.3-53.9,329.1-161.7c107.8-107.8,161.7-216.6,161.7-326.4 c0-13-1.2-29.5-3.4-49.6c-62.5,57.9-112.7,96.4-150.7,115.6c-38,19.2-74.1,28.8-108.3,28.8c-39.5,0-68.4-10.6-86.6-31.9 c-18.3-21.3-27.4-43.7-27.4-67.3c0-41.9,18.2-81.2,54.5-117.8c36.3-36.6,76.6-60.5,120.9-71.7l170.1-41.6 c0.6-63.2,22.6-116,66-158.6c43.4-42.5,87.5-63.8,132.4-63.8c20.1,0,35.9,5,47.4,15.1c11.5,10.1,17.3,23.3,17.3,39.9 c0,31.3-16.7,62-50,92.1c-33.4,30.1-100.2,57.9-200.6,83.3c1.8,42.5,8.9,88.3,21.3,137.3c82.7-79.7,143.3-131.4,182-155 c38.7-23.6,72.8-35.4,102.3-35.4c23.6,0,42.6,5.9,57.1,17.7c14.5,11.8,21.7,24.8,21.7,39c0,10.1-4.1,19-12.4,27 c-8.3,8-17.1,12-26.6,12c-8.9,0-16.5-3.2-23-9.7c-6.5-6.5-9.7-14.5-9.7-23.9c0-12.4,4.9-21.8,14.6-28.3 c9.7-6.5,14.6-10.9,14.6-13.3c0-2.9-4.3-5.9-12.8-8.9c-8.6-2.9-16.4-4.4-23.5-4.4c-21.3,0-42.1,4.9-62.4,14.6 c-20.4,9.7-50.6,32.5-90.8,68.2C1078.9,454.7,1036.3,498,991.5,548.7z M975.8,531l-12.1-78.8c-4.6-28.3-7.5-44-8.6-46.9l-0.9-20.4 c-76.8,21.3-133.3,43.8-169.6,67.8c-36.3,23.9-68.4,55.8-96.1,95.7c-27.8,39.9-41.6,70.7-41.6,92.6c0,15.4,6.2,28.3,18.6,39 c12.4,10.6,28.9,15.9,49.5,15.9c38.9,0,82.4-14.6,130.4-43.8C893.4,622.7,936.9,582.4,975.8,531z M964,370.7 c77.3-14.7,137.1-37.5,179.4-68.2c42.2-30.7,63.3-62.9,63.3-96.5c0-30.1-18.9-45.2-56.7-45.2c-45.5,0-87.8,21.4-127.1,64.2 C983.6,267.8,964,316.4,964,370.7z"
        fill="currentColor"
      />
    </svg>
  );
}
